import { useWindowDimensions } from "@ggl/components"
import { useCallback, useMemo } from "react"

const DEFAULT_BREAKPOINTS = {
  mobile: 480,
  tablet: 860
  // landscape tablet is considered desktop
}

export type Device = "mobile" | "tablet" | "desktop"

export default function useDevice(breakpoints = DEFAULT_BREAKPOINTS) {
  const { width } = useWindowDimensions()

  const device = useMemo<Device>(() => {
    if (width <= breakpoints.mobile) return "mobile"
    if (width <= breakpoints.tablet) return "tablet"
    return "desktop"
  }, [width])

  const isDevice = useCallback(
    (...devices: (typeof device)[]) => {
      return devices.includes(device)
    },
    [device]
  )

  return { device, isDevice }
}
