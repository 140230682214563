import { LinkWithName } from "../types/api.link.type"

const NOT_ON_SITE = "Not on site"
const NEVER_ACCESSED = "Never accessed"

const getLastAccessZoneName = (lastSuccessfulAccessZone?: LinkWithName, lastSuccessfulAccessTime?: string) => {
  if (lastSuccessfulAccessZone?.name) return lastSuccessfulAccessZone.name
  if (!lastSuccessfulAccessZone && lastSuccessfulAccessTime) {
    return NOT_ON_SITE
  } else {
    return NEVER_ACCESSED
  }
}

export default getLastAccessZoneName
