import {
  CARDHOLDER_SEARCH_COLUMN_FIELDS,
  type CardholderSearchColumn,
  type CardholderSearchFieldColumn,
  type CardholderSearchNonSelectedColumn,
  type CardholderSearchPdfColumn,
  type SiteSettings
} from "../types/api.settings.type"

export const DEFAULT_CARDHOLDER_SEARCH_COLUMNS: CardholderSearchColumn[] = [
  {
    name: "firstName"
  },
  {
    name: "lastName"
  },
  {
    name: "description"
  },
  {
    name: "division"
  },
  {},
  {}
]

export function isCardholderSearchFieldColumn(column: unknown): column is CardholderSearchFieldColumn {
  const name = (column as CardholderSearchFieldColumn)?.name
  return name !== undefined && typeof name === "string" && CARDHOLDER_SEARCH_COLUMN_FIELDS.includes(name)
}

export function isCardholderSearchPdfColumn(column: unknown): column is CardholderSearchPdfColumn {
  const id = (column as CardholderSearchPdfColumn)?.pdfId
  return id !== undefined && typeof id === "number"
}

export function isCardholderSearchNonSelectedColumn(column: unknown): column is CardholderSearchNonSelectedColumn {
  return typeof column === "object" && !Array.isArray(column) && !!column && Object.keys(column).length === 0
}

export function isValidCardholderSearchColumn(column: unknown): boolean {
  return !!column && !Array.isArray(column) && (isCardholderSearchFieldColumn(column) || isCardholderSearchPdfColumn(column) || isCardholderSearchNonSelectedColumn(column))
}

function isValidCardholderSearchColumns(columns: unknown): boolean {
  return Array.isArray(columns) && columns.every(isValidCardholderSearchColumn)
}

export function getCardholderSearchColumnsOrDefault(settings?: SiteSettings): CardholderSearchColumn[] {
  try {
    const columnsJson = settings?.["ccweb.cardholderSearch.columns"]
    if (columnsJson) {
      const deserialized = JSON.parse(columnsJson)
      if (isValidCardholderSearchColumns(deserialized)) {
        return deserialized
      }
    }
  } catch (e: unknown) {
    console.warn("ccweb.cardholderSearch.columns is not a valid JSON or not valid")
  }

  return DEFAULT_CARDHOLDER_SEARCH_COLUMNS
}
