/**
 * Return the truncated(with ellipsis at the end) string if string is longer than input length
 *
 * @Remarks
 *  return string will be always minimum length of 3 or will be equal to input length if input string length is greter than input length
 *  return string will be same as input string if input string length is less or equal to input length
 *
 * @param str - The String to be truncated
 * @param len - Length of the return string to be
 * @returns truncated string with minimum len of 3 or same input string
 *  */
const truncate = (str: string, len: number) => {
  const returnStrLen = Math.max(3, len) //... have length of 3
  return str.length > returnStrLen ? str.slice(0, returnStrLen - 3) + "..." : str
}

export default truncate