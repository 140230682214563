import { ContextMenu, DEFAULT_SEARCH_DEBOUNCE_DELAY, DropDown, IconButton, OnSubmitTrigger, SearchField, SelectableItemProps, StylableComponent, useWindowDimensions } from "@ggl/components"
import React, { useCallback, useRef } from "react"
import useDevice from "../../common/utils/useDevice"
import { useAppDispatch, useAppSelector } from "../../store"
import { queryChanged } from "./cardholderSimpleSearch.slice"
import { SEARCH_FIELD_MAX_LENGTH } from "./cardholderSimpleSearch.util"
import styles from "./cardholderSimpleSearchForm.module.scss"

const SEARCH_BY_COLUMN = "Search by Column"

interface CardholderSimpleSearchFormProps extends StylableComponent {
  isSearchBarLoading: boolean
  isSearchFilterLoading: boolean
  filterDropdownItems: SelectableItemProps[]
  onSearchSubmit: (trigger: OnSubmitTrigger) => void
  onFilterChange: (val?: [string, string | number] | undefined) => void
}

/**
 * This contains the searchbar and the search filter.
 */
const CardholderSimpleSearchForm = ({ className, isSearchBarLoading, isSearchFilterLoading, filterDropdownItems, onSearchSubmit, onFilterChange }: CardholderSimpleSearchFormProps) => {
  const state = useAppSelector((state) => state.cardholderSearch)
  const dispatch = useAppDispatch()
  const { device } = useDevice()
  const { width: windowWidth } = useWindowDimensions()
  const optionsButtonRef = useRef<HTMLDivElement | null>(null)
  const [isOptionsOpen, setIsOptionsOpen] = React.useState(false)

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(queryChanged(e.target.value))
    },
    [dispatch]
  )

  const filterDropdown = (
    <div className={styles.filterDropdown}>
      <DropDown
        kvp
        showError={false}
        data={filterDropdownItems}
        value={state.filter}
        onChange={(val?: [string, string | number] | undefined) => {
          onFilterChange(val)
          setIsOptionsOpen(false)
        }}
        label={device === "mobile" ? SEARCH_BY_COLUMN : undefined}
        asActionSheet={false}
        id="cardholder_search_filter"
        showSkeleton={isSearchFilterLoading}
      />
    </div>
  )

  const optionsButtonDimensions = optionsButtonRef.current?.getBoundingClientRect()
  const oneRemInPx = 1 * parseFloat(getComputedStyle(document.documentElement, null).getPropertyValue("font-size"))
  const optionsPopupDimensions = {
    top: optionsButtonDimensions?.bottom ?? 0 + oneRemInPx,
    height: 200,
    width: 240,
    left: windowWidth - 240 - oneRemInPx
  }

  return (
    <form className={`${className} ${styles.form}`}>
      <SearchField
        id="cardholder_search"
        autoFocus
        isLoading={isSearchBarLoading}
        value={state.query}
        className={styles.search}
        type={"search"}
        placeholder="Search"
        onChange={handleSearchChange}
        onSubmit={onSearchSubmit}
        size="medium"
        maxLength={SEARCH_FIELD_MAX_LENGTH}
        debounce={DEFAULT_SEARCH_DEBOUNCE_DELAY}
        autoSubmit
      />

      {device === "mobile" && (
        <div ref={optionsButtonRef}>
          <IconButton className={styles.optionsBtn} icon="tune" color="none" variant="no-border" onClick={() => setIsOptionsOpen(true)} />
        </div>
      )}
      {device === "mobile" && isOptionsOpen && (
        <ContextMenu asActionSheet={false} dimensions={optionsPopupDimensions} closeHandler={() => setIsOptionsOpen(false)}>
          {filterDropdown}
        </ContextMenu>
      )}

      {device !== "mobile" && filterDropdown}
    </form>
  )
}

export default CardholderSimpleSearchForm
