import { Avatar, Button, Chip, ColumnConfig, ErrorMessage, Icon, OnSubmitTrigger, Table } from "@ggl/components"
import { useRouter } from "next/navigation"
import { useCallback, useEffect, useMemo } from "react"
import { CardholderWithThumbnail } from "../../common/types/api.cardholder.type"
import { LinkWithIdAndName } from "../../common/types/api.link.type"
import { PDFItem } from "../../common/types/api.personalDataField.type"
import { Results } from "../../common/types/api.search.type"
import { CardholderSearchColumn, convertOperatorSettingsToPatch } from "../../common/types/api.settings.type"
import { FTItemType, VerbId } from "../../common/types/commandCentreTypes"
import { getCardholderSearchColumnsOrDefault, isCardholderSearchFieldColumn, isCardholderSearchPdfColumn } from "../../common/utils/api.settings.utils"
import getLastAccessZoneName from "../../common/utils/getLastAccessZoneName"
import { captureTelemetry } from "../../common/utils/postHog"
import truncate from "../../common/utils/truncate"
import useDevice, { Device } from "../../common/utils/useDevice"
import {
  selectCardholders,
  selectCardholderViewableDivisions,
  selectOperatorSettingsUpdateUrl,
  selectOperatorSettingsUrl,
  selectSiteSettingsUrl,
  selectThumbnailUrl,
  useAppDispatch,
  useAppSelector
} from "../../store"
import {
  useCanDoVerbQuery,
  useGetDivisionsQuery,
  useGetOperatorSettingsQuery,
  useGetPersonalDataFieldDefinitionsQuery,
  useGetSiteSettingsQuery,
  useLazyGetCardholdersWithThumbnailsQuery,
  useUpdateOperatorSettingsMutation
} from "../../store/api.slice"
import styles from "./cardholderSimpleSearch.module.scss"
import {
  filterChanged,
  loadMoreDone,
  loadMoreFailed,
  searchDone,
  searchFailed,
  searchSubmitted,
  selectApiError,
  selectFilter,
  selectHasDoneInitialSearch,
  selectHasMoreResults,
  selectQuery,
  selectQueryError,
  selectResults,
  selectSort,
  selectTop
} from "./cardholderSimpleSearch.slice"
import { CardholderSimpleSearchFilter, isCardholderSimpleSearchPdfFilter } from "./cardholderSimpleSearch.type"
import { COLUMN_NAMES, getPdfColumnNameOrDefault, getSearchArgs, getSearchFiltersSelectableItemProps, MAX_SEARCH_QUERY_LENGTH_FOR_ERROR } from "./cardholderSimpleSearch.util"
import CardholderSimpleSearchForm from "./cardholderSimpleSearchForm"

const YES = "Yes"
const NO = "No"
const TRY_AGAIN = "Try another search"
const NETWORK_ISSUES = "Currently experiencing connection issues"
const ERROR_WITH_QUERY = "Error with search query"
const ADD_CARDHOLDER = "Add Cardholder"

const NAME_SEARCH_QUERY_SPECIAL_CHARACTERS_ERROR = "Name cannot contain special characters"

const NoResultsMessage = (query: string) => (
  <div title={query}>
    No results were found for <em>`{truncate(query, MAX_SEARCH_QUERY_LENGTH_FOR_ERROR)}`</em>
  </div>
)

export const getTableColumns = (searchColumns: CardholderSearchColumn[], pdfDefinitions?: Results<PDFItem>, divisions?: Results<LinkWithIdAndName>, device?: Device) => {
  const columns: ColumnConfig<CardholderWithThumbnail>[] = [
    {
      title: "",
      width: "48px",
      renderer: ({ thumbnail: src, firstName, lastName }: CardholderWithThumbnail) => <Avatar size={"small"} {...{ src, firstName, lastName }} />
    }
  ]

  for (const [i, column] of searchColumns.entries()) {
    const isVisible = () => {
      if (device === "mobile") return i < 2
      else if (device === "tablet") return i < 4
      else return true
    }

    if (isCardholderSearchFieldColumn(column)) {
      const columnConfig = {
        title: COLUMN_NAMES[column.name],
        isVisible
      } as ColumnConfig<CardholderWithThumbnail>

      switch (column.name) {
        case "division":
          columnConfig.renderer = (row) => divisions?.results.find((division) => division.href === row.division?.href)?.name ?? ""
          break
        case "lastZone":
          columnConfig.renderer = (row) => {
            let accessZoneTime = ""
            if (row.lastSuccessfulAccessTime) {
              const parsedDateTime = new Date(row.lastSuccessfulAccessTime)
              if (!isNaN(parsedDateTime.getDate())) {
                accessZoneTime = parsedDateTime.toLocaleString()
              }
            }

            const combined = `${accessZoneTime} ${getLastAccessZoneName(row.lastSuccessfulAccessZone, row.lastSuccessfulAccessTime)}`
            return <span data-time={row.lastSuccessfulAccessTime}>{combined.trim()}</span>
          }
          break
        case "cardNumbers":
          columnConfig.renderer = (row) => row.cards?.map((card) => card.number).join(", ") ?? ""
          break
        case "authorised":
          columnConfig.renderer = (row) => <Chip type="message" name={row.authorised ? YES : NO} color={row.authorised ? "success" : "destructive"} size="small" />
          break
        default:
          columnConfig.renderer = (row) => row[column.name as keyof CardholderWithThumbnail]?.toString() ?? ""
          break
      }

      columns.push(columnConfig)
    } else if (isCardholderSearchPdfColumn(column) && pdfDefinitions?.results.some((pdf) => pdf.id === column.pdfId.toString())) {
      const name = getPdfColumnNameOrDefault(column.pdfId, pdfDefinitions)
      columns.push({
        title: name,
        renderer(row) {
          const pdf = row.personalDataDefinitions?.find((pdf) => pdf.hasOwnProperty(`@${name}`))
          const type = pdf?.[`@${name}`]?.definition?.type
          const value = pdf?.[`@${name}`]?.value ?? ""
          if (type === "date" && typeof value === "string") {
            const date = new Date(value)
            return !isNaN(date.getDate()) ? date.toLocaleDateString() : ""
          }

          return pdf?.[`@${name}`]?.value?.toString() ?? ""
        },
        isVisible
      })
    }
  }

  if (device === "desktop") {
    columns.push({
      title: "",
      className: styles.openInNewIcon,
      renderer: () => <Icon type="open-in-new" />
    })
  }

  return columns
}

const CardholderSimpleSearch = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const cardholderResults = useAppSelector(selectResults)
  const query = useAppSelector(selectQuery)
  const filter = useAppSelector(selectFilter)
  const apiError = useAppSelector(selectApiError)
  const queryError = useAppSelector(selectQueryError)
  const top = useAppSelector(selectTop)
  const sort = useAppSelector(selectSort)
  const hasDoneInitialSearch = useAppSelector(selectHasDoneInitialSearch)
  const { device } = useDevice()

  // site settings
  const getSettingsUrl = useAppSelector(selectSiteSettingsUrl)
  const siteSettingsResult = useGetSiteSettingsQuery({ url: getSettingsUrl, fields: ["ccweb.cardholderSearch.columns"] })
  const searchColumns = useMemo(() => getCardholderSearchColumnsOrDefault(siteSettingsResult.data), [siteSettingsResult.data])

  // operator settings
  const getOperatorSettingsUrl = useAppSelector(selectOperatorSettingsUrl)
  const updateOperatorSettingsUrl = useAppSelector(selectOperatorSettingsUpdateUrl)
  const operatorSettingsResult = useGetOperatorSettingsQuery({ url: getOperatorSettingsUrl, fields: ["ccweb.cardholderSearch.lastSimpleSearchFilter"] })
  const [updateOperatorSettings] = useUpdateOperatorSettingsMutation()

  // pdfs
  const pdfResult = useGetPersonalDataFieldDefinitionsQuery({ url: undefined, fields: ["id", "name", "type"] })

  // divisions
  const viewableDivisionsUrl = useAppSelector(selectCardholderViewableDivisions)
  const divisionsResult = useGetDivisionsQuery({ url: viewableDivisionsUrl })

  const fetchSiteSettingsComplete = siteSettingsResult.isSuccess || siteSettingsResult.isError
  const fetchOperatorSettingsComplete = operatorSettingsResult.isSuccess || operatorSettingsResult.isError
  const fetchPdfsComplete = pdfResult.isSuccess || pdfResult.isError
  const fetchDivisionsComplete = divisionsResult.isSuccess || divisionsResult.isError
  const hasFetchedAll = fetchSiteSettingsComplete && fetchOperatorSettingsComplete && fetchPdfsComplete && fetchDivisionsComplete

  // cardholder search
  const getCardholdersUrl = useAppSelector(selectCardholders)
  const getCardholderThumbnailsUrl = useAppSelector(selectThumbnailUrl)
  const hasMoreResults = useAppSelector(selectHasMoreResults)
  const [search, searchResult] = useLazyGetCardholdersWithThumbnailsQuery()
  const [loadMore, loadMoreResult] = useLazyGetCardholdersWithThumbnailsQuery()
  const filterDropdownItems = useMemo(
    () => (fetchSiteSettingsComplete ? getSearchFiltersSelectableItemProps(searchColumns, pdfResult.data) : []),
    [fetchSiteSettingsComplete, searchColumns, pdfResult]
  )

  // Do the initial search once we have all the data
  useEffect(() => {
    if (!hasDoneInitialSearch && hasFetchedAll) {
      search({ ...getSearchArgs(getCardholdersUrl, getCardholderThumbnailsUrl, query, filter, sort, top, searchColumns), tag: new Date().toISOString() })
      dispatch(searchSubmitted())
      captureTelemetry("Searched Cardholders", {
        filter: filter,
        isPdfFilter: isCardholderSimpleSearchPdfFilter(filter),
        pdfType: pdfResult.data?.results.find((pdf) => pdf.id === filter?.replace("pdf_", ""))?.type,
        trigger: "mount" as OnSubmitTrigger
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedAll, hasDoneInitialSearch, getCardholdersUrl, getCardholderThumbnailsUrl, dispatch, search])

  useEffect(() => {
    if (searchResult.isError) dispatch(searchFailed())
    else if (searchResult.data === undefined) return
    else if (searchResult.isSuccess) dispatch(searchDone(searchResult.data))
  }, [searchResult.data, searchResult.isSuccess, searchResult.isError, dispatch])

  useEffect(() => {
    if (loadMoreResult.isError) dispatch(loadMoreFailed())
    else if (loadMoreResult.data === undefined) return
    else if (loadMoreResult.isSuccess) dispatch(loadMoreDone(loadMoreResult.data))
  }, [loadMoreResult.data, loadMoreResult.isSuccess, loadMoreResult.isError, dispatch])

  // table
  const tableColumns = useMemo(() => getTableColumns(searchColumns, pdfResult.data, divisionsResult.data, device), [searchColumns, pdfResult, divisionsResult, device])

  // On first load, set initial filter once we have the columns and operator settings loaded
  // 1. Operator preference if it exists
  // 2. Name
  // 3. First available filter
  useEffect(() => {
    if (hasDoneInitialSearch || !fetchSiteSettingsComplete || !fetchOperatorSettingsComplete || !fetchPdfsComplete) return

    const savedSearchFilter = operatorSettingsResult.data?.["ccweb.cardholderSearch.lastSimpleSearchFilter"]
    const isSavedSearchFilterInDropdown = savedSearchFilter && filterDropdownItems.find((item) => item.value === savedSearchFilter) !== undefined
    if (savedSearchFilter && isSavedSearchFilterInDropdown) {
      if (filter !== savedSearchFilter) {
        dispatch(filterChanged(savedSearchFilter as CardholderSimpleSearchFilter))
      }
    } else if (filterDropdownItems.find((item) => item.value === ("name" as CardholderSimpleSearchFilter))) {
      dispatch(filterChanged("name" as CardholderSimpleSearchFilter))
    } else if (filterDropdownItems[0].value !== filter) {
      dispatch(filterChanged(filterDropdownItems[0].value as CardholderSimpleSearchFilter))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDoneInitialSearch, filterDropdownItems, fetchSiteSettingsComplete, fetchOperatorSettingsComplete, dispatch, operatorSettingsResult.data])

  // cardholder create
  const { canCreateCardholder } = useCanDoVerbQuery(
    {
      verb: VerbId.New,
      type: FTItemType.Cardholder
    },
    {
      selectFromResult: ({ data, isError }) => ({ canCreateCardholder: data?.isPrivileged ?? true })
    }
  )

  const handleSearchSubmit = useCallback(
    (trigger: OnSubmitTrigger) => {
      if (queryError === "QUERY_CONTAINS_SPECIAL_CHARS" || !hasDoneInitialSearch || !hasFetchedAll) return

      search({ ...getSearchArgs(getCardholdersUrl, getCardholderThumbnailsUrl, query, filter, sort, top, searchColumns), tag: new Date().toISOString() })
      dispatch(searchSubmitted())

      captureTelemetry("Searched Cardholders", {
        filter: filter,
        isPdfFilter: isCardholderSimpleSearchPdfFilter(filter),
        pdfType: pdfResult.data?.results.find((pdf) => pdf.id === filter?.replace("pdf_", ""))?.type,
        trigger
      })
    },
    [queryError, search, dispatch, hasFetchedAll, hasDoneInitialSearch, query, filter, sort, top, getCardholdersUrl, getCardholderThumbnailsUrl, searchColumns, pdfResult]
  )

  const handleFilterChange = useCallback(
    (val?: [string, string | number] | undefined) => {
      if (val === undefined) return

      const value = val[1]
      if (typeof value === "string" && filter !== value) {
        const newFilter = value as CardholderSimpleSearchFilter
        dispatch(filterChanged(newFilter))
        updateOperatorSettings({ url: updateOperatorSettingsUrl, settings: convertOperatorSettingsToPatch({ "ccweb.cardholderSearch.lastSimpleSearchFilter": newFilter }) })

        if (query !== "") {
          search({
            ...getSearchArgs(getCardholdersUrl, getCardholderThumbnailsUrl, query, newFilter, sort, top, searchColumns),
            tag: new Date().toISOString()
          })
          captureTelemetry("Searched Cardholders", {
            filter: newFilter,
            isPdfFilter: isCardholderSimpleSearchPdfFilter(newFilter),
            pdfType: pdfResult.data?.results.find((pdf) => pdf.id === newFilter.replace("pdf_", ""))?.type,
            trigger: "filter changed"
          })
        }

        captureTelemetry("Changed Search Filter", {
          filter: newFilter,
          isPdfFilter: isCardholderSimpleSearchPdfFilter(newFilter),
          pdfType: pdfResult.data?.results.find((pdf) => pdf.id === newFilter.replace("pdf_", ""))?.type
        })
      }
    },
    [dispatch, search, getCardholdersUrl, getCardholderThumbnailsUrl, query, sort, top, searchColumns, filter, pdfResult, updateOperatorSettings]
  )

  const handleLoadMoreClick = useCallback(() => {
    if (searchResult.isFetching) return

    if (cardholderResults.next) {
      loadMore({ getCardholderThumbnailsUrl, next: cardholderResults.next.href })
      captureTelemetry("Clicked (View More) Cardholders")
    }
  }, [searchResult.isFetching, cardholderResults.next, loadMore, getCardholderThumbnailsUrl])

  const handleRowClick = useCallback(
    ({ id }: CardholderWithThumbnail) => {
      captureTelemetry("Clicked on a Cardholder")
      router.push(`/people?id=${id}`)
    },
    [router]
  )

  const showTable = !queryError && hasFetchedAll
  const showQueryError = !!queryError
  const showApiError = !queryError && !!apiError
  const showNoResults = !showQueryError && !searchResult.isFetching && query.length > 0 && cardholderResults.results.length === 0
  const showLoadMoreButton = showTable && hasMoreResults
  const isSearchBarLoading = searchResult.isFetching

  return (
    <div className={styles.root}>
      <div className={styles.searchHeader}>
        <CardholderSimpleSearchForm
          className={styles.searchForm}
          isSearchBarLoading={isSearchBarLoading}
          isSearchFilterLoading={!fetchSiteSettingsComplete}
          filterDropdownItems={filterDropdownItems}
          onSearchSubmit={handleSearchSubmit}
          onFilterChange={handleFilterChange}
        />
        <Button
          color="primary"
          leftIcon="add"
          className={styles.addButton}
          onClick={() => {
            router.push("/people/add")
            captureTelemetry("Clicked (Add Cardholder) Button")
          }}
          disabled={!canCreateCardholder}
          size={"medium"}
          fullWidth={device === "mobile"}
        >
          {ADD_CARDHOLDER}
        </Button>
      </div>
      <div className={styles.panel}>
        {showTable && (
          <Table<CardholderWithThumbnail>
            data={cardholderResults.results ?? []}
            id="cardholder-search-results"
            key={"table"}
            onClickRow={handleRowClick}
            className={styles.table}
            columns={tableColumns}
          />
        )}

        {showQueryError && <ErrorMessage message={NAME_SEARCH_QUERY_SPECIAL_CHARACTERS_ERROR} title={ERROR_WITH_QUERY} />}
        {showApiError && <ErrorMessage title={NETWORK_ISSUES} />}
        {showNoResults && <ErrorMessage title={NoResultsMessage(query)} message={TRY_AGAIN} />}
        {showLoadMoreButton && (
          <div className={styles.loadButton}>
            <Button onClick={handleLoadMoreClick} text="Load More" id="load_button" loading={loadMoreResult.isFetching} disabled={loadMoreResult.isFetching} color="primary" />
          </div>
        )}
      </div>
    </div>
  )
}

export default CardholderSimpleSearch
