import { selectProfileImageIds, selectThumbnailUrl } from "../../store/"
import { store } from "../../store/store"
import { Thumbnail } from "../types/api.cardholder.type"
import { get } from "./apiCall"
import { appendQueryString } from "./appendQueryString"
import invariant from "./invariant"

/**
 * This will return a collection of thumbnail items from a provided collection of ids
 * @deprecated use RTK Query instead
 */
export async function getMultipleThumbnails(ids: Array<string | number>, width = 50, height = 50) {
  // Get the thumbnail PDFs from redux, these are stored
  const pdfIds = selectProfileImageIds(store.getState())
  // if we don't have a PDF to use just finish processing here
  if (!pdfIds || pdfIds.length === 0) return []

  const url = selectThumbnailUrl(store.getState())
  if (!url) return []

  const queryValues = {
    sourcePdf: pdfIds.join(","),
    ids: ids.join(","),
    maxHeight: height.toString(),
    maxWidth: width.toString()
  }

  try {
    const json = await get<{ results: Array<Thumbnail> }>(appendQueryString(url, queryValues))
    invariant(json)
    // append the base64 tag here so we don't need to do it when its consumed
    json.results.forEach((item) => {
      item.thumbnail = item.thumbnail ? `data:image/jpeg;base64, ${item.thumbnail}` : undefined
    })
    return json.results
  } catch (err: any) {
    // Thumbnails are niceties, so just return an empty array instead forcing error handling in the calling code
    return [] as Thumbnail[]
  }
}
