export default function unique<TData, TKey>(arr: TData[], keySelector: (item: TData) => TKey = (item: TData) => item as unknown as TKey): TData[] {
  if (arr.length == 0) return []

  const map = arr.reduce((acc, item) => {
    if (!acc.has(keySelector(item))) {
      acc.set(keySelector(item), item)
    }

    return acc
  }, new Map())
  return [...map.values()]
}
